<template>
  <v-container>
    <v-tabs
      v-model="tab"
      value="tabvalue"
      align-with-title
      background-color="teal lighten-4"
      class="mx-0"
    >
      <v-tab> Unallocated</v-tab>
      <v-tab> Allocated</v-tab>
      <v-tab> All</v-tab>
      <v-tab-item>
        <v-text-field
          class="mx-4 my-4"
          flat
          hide-details
          label="Search Bib Number"
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-pound"
          solo-inverted
          v-model="overallSearch"
        ></v-text-field>
        <v-layout>
          <v-row v-if="loading">
            <v-col cols="4" md="2" v-for="n in 48" :key="n"
              ><v-skeleton-loader
                v-bind="attrs"
                type="card"
                height="80px"
              ></v-skeleton-loader
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="4" v-for="bib in searchBibfn" :key="bib.number" md="2">
              <v-card @click="assignDialog(bib.number)">
                <v-card-title
                  ><v-avatar v-bind:color="divisionColor[bib.division]"
                    >{{ bib.number }}
                  </v-avatar>
                  <v-spacer> </v-spacer>
                  <v-chip small> {{ bib.division }} </v-chip>
                </v-card-title>
                <v-card-text>
                  <v-chip small v-if="bib.allocated"
                    ><v-icon>mdi-check </v-icon> Allocated</v-chip
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-layout>
      </v-tab-item>
      <v-tab-item>
        <v-text-field
          class="mx-4"
          flat
          hide-details
          label="Search Bib Number"
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-pound"
          solo-inverted
          v-model="overallSearch"
        ></v-text-field>
        <v-layout>
          <v-row>
            <v-col
              cols="6"
              v-for="racer in searchBibfn"
              :key="racer.bib"
              md="2"
            >
              <v-card outlined>
                <v-card-title
                  ><v-avatar color="teal">{{ racer.bib }} </v-avatar>
                  <v-spacer> </v-spacer>
                  <v-chip small v-bind:color="divisionColor[racer.division]">
                    {{ racer.division }}
                  </v-chip>
                </v-card-title>
                <v-card-text
                  ><v-layout
                    ><v-row
                      ><v-col>
                        <v-chip small>
                          {{ racer.club }}
                        </v-chip> </v-col
                      ><v-col> {{ racer.name }} </v-col>
                    </v-row></v-layout
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-layout></v-tab-item
      >
      <v-tab-item>
        <v-text-field
          class="mx-4"
          flat
          hide-details
          label="Search Bib Number"
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-pound"
          solo-inverted
          v-model="overallSearch"
        ></v-text-field>
        <v-layout>
          <v-row>
            <v-col cols="6" v-for="bib in searchBibfn" :key="bib.number" md="2">
              <v-card outlined>
                <v-card-title
                  ><v-avatar color="teal">{{ bib.number }} </v-avatar>
                  <v-spacer> </v-spacer>
                  <v-chip small>
                    {{ bib.division }}
                  </v-chip>
                </v-card-title>
                <v-card-text
                  ><v-layout
                    ><v-row
                      ><v-col> <v-chip small> club </v-chip> </v-col
                      ><v-col> </v-col> </v-row
                  ></v-layout>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row> </v-layout
      ></v-tab-item>
    </v-tabs>
    <v-dialog v-model="assignBibDialog" max-width="800px" min-width="350px">
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            Find Racer
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            Check Details
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3"> Assign Bib</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card class="mb-6" color="grey lighten-4" height="350px">
              <v-card-title>
                <v-text-field
                  label="Racer Name"
                  prepend-icon="mdi-account-search"
                  v-model="searchRacer"
                ></v-text-field></v-card-title
              ><v-card-text>
                <v-data-table
                  dense
                  @click:row="stepTwo"
                  :headers="racerSelectHeaders"
                  :items="searchRacerfn"
                  :search="searchRacer"
                  disable-pagination
                  disable-sort
                  hide-default-header
                  hide-default-footer
                  ><v-data-table-footer
                    disable-items-per-page
                    disable-pagination
                  ></v-data-table-footer>
                </v-data-table>
              </v-card-text>
            </v-card>

            <v-btn color="primary" @click="e1 = 2"> Continue </v-btn>

            <v-btn text @click="cancelStepper"> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="mb-12" color="grey lighten-1" height="300px">
              <v-card-title>
                <v-icon>mdi-account</v-icon>{{ racerToBeAssigned.name }}
              </v-card-title>

              <v-card-text>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-shield-home</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      {{ racerToBeAssigned.club }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-calendar</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      {{ racerToBeAssigned.dob }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-calendar</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      {{ racerToBeAssigned.division }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-gender-male-female</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      {{ racerToBeAssigned.gender }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon><v-icon>mdi-at</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      {{ racerToBeAssigned.email }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

            <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>

            <v-btn text @click="cancelStepper"> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card class="mb-12" color="grey lighten-4" height="200px">
              <v-card-title>You are assigning</v-card-title>
              <v-layout
                ><v-row class="avatarrow">
                  <v-col></v-col>
                  <v-col class="vertBib">
                    <avatar
                      color="primary "
                      :username="bibToBeAssigned"
                      :initials="bibToBeAssigned"
                      size=72
                    ></avatar>
                    <div>Bib #{{ bibToBeAssigned }}</div>
                  </v-col>
                  <v-col>
                    <v-avatar size=128
                      ><v-icon>mdi-arrow-right</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col class="vertBib">
                    <avatar
                      :username="racerToBeAssigned.name"
                      
                      size=72
                    ></avatar>

                    <div>{{ racerToBeAssigned.name }}</div>
                  </v-col>
                </v-row></v-layout
              >
            </v-card>

            <v-btn color="primary" @click="submitRacerWithBib()">
              Assign
            </v-btn>

            <v-btn text @click="cancelStepper"> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import Avatar from "vue-avatar";

export default {
  components: {
    Avatar,
  },
  data() {
    return {
      e1: null,
      racerSelectHeaders: [
        {
          text: "Name",
          align: "start",

          value: "name",
        },
        { text: "Club", value: "club" },
      ],
      racerToBeAssigned: { name: "" },
      bibToBeAssigned: "",
      overallSearch: "",
      assignBibDialog: false,
      searchRacer: "",
      loading: true,
      tab: null,
      divisions: [],
      tabvalue: null,
      racers: [],
      racersWithBibs: [],
      tabs: [false, true],
      bibs: [],
      filterDivision: "Junisor",
      divisionColor: {
        Open: "green",
        Junior: "red",
        Snowboard: "blue",
        None: "grey lighten-2",
      },
      columns: [
        {
          label: "Bib",
          field: "number",
          width: "60px",
        },
        {
          label: "Division",
          field: "division",
          width: "100px",
        },
        {
          label: "Notes",
          field: "notes",
        },
        {
          label: "Allocated?",
          field: "allocated",
          type: "boolean",
          formatFn: this.allocatedBox,
        },
      ],
    };
  },

  methods: {
        async getDivisionData() {
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/divisions"
        );
        // JSON responses are automatically parsed.
        console.log(response);
        this.divisions = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async submitAllocateBib(bib) {

      console.log("Fuck me we made it this far!")
      try {
        var editBib = {
          id: bib,
          number: bib,
          division: this.racerToBeAssigned.divisionID,
          //division: 4,
          //notes: this.editBib.notes,
          allocated: 1,
        };



        console.log("ZZTOP: " + editBib);
        const response = await axios.put(
          "https://dev.app1.rsc.org.nz/bib/" + bib,
          editBib
        );
        // JSON responses are automatically parsed.
        console.log(response);
        //this.racers = response.data;
      } catch (error) {
        console.log(error);
        this.errorMsg = error;
        this.errorPopup = true;
      } finally {
        //this.loading = false
        //Close the form

        if (!this.errorPopup) {
          this.successMsg = "Success! Bib updated!";
          this.successPopup = true;
          this.assignBibDialog = false;
          this.e1 = null;
        }
        this.getData() //Request to update the data
        this.searchBibfn() // Update the search results NOT WORKING
        this.filteredBibs() // Update the filtered bibs NOTWORKING
      }
    },
    async submitRacerWithBib() {
      try {
       
        const editRacer = {
          id: this.racerToBeAssigned.id,
          bib: this.bibToBeAssigned,
          name: this.racerToBeAssigned.name,
          dob: this.racerToBeAssigned.dob,
          gender: this.racerToBeAssigned.gender,
          phone: this.racerToBeAssigned.phone,
          email: this.racerToBeAssigned.email,
          professional: this.racerToBeAssigned.professional,
          division: this.racerToBeAssigned.divisionID,
          dnf:0,
          dsq:0,
        };

        const response = await axios.put(
          "https://dev.app1.rsc.org.nz/racer",
          editRacer
        );
        // JSON responses are automatically parsed.
        console.log(response);
        //this.racers = response.data;
      } catch (error) {
        console.log(error);
        this.errorMsg = error;
        this.errorPopup = true;
      } finally {
        this.loading = false;
        //Mark Bib Assigned.
        this.submitAllocateBib(this.bibToBeAssigned);
        
        if (!this.errorPopup) {
          this.successMsg = "Success! Racer updated!";
          this.successPopup = true;
          this.editDialog = false;
        }
        this.getData();
      }
    },
    cancelStepper() {
      this.assignBibDialog = false;
      this.e1 = 1;
    },
    stepTwo(event) {
      this.e1 = this.e1 + 1;
      this.racerToBeAssigned = event;
      console.log("atta boy : ");
      console.log(event);
       if (this.racerToBeAssigned.division == "Open") {
          this.racerToBeAssigned.divisionID = 1;
        }
        if (this.racerToBeAssigned.division == "Junior") {
          this.racerToBeAssigned.divisionID = 2;
        }
        if (this.racerToBeAssigned.division == "Masters") {
          this.racerToBeAssigned.divisionID = 3;
        }
        if (this.racerToBeAssigned.division == "Snowboard") {
          this.racerToBeAssigned.divisionID = 4;
        }
    },
    assignDialog(bib) {
      this.assignBibDialog = true;
      this.bibToBeAssigned = bib;
    },
    allocatedBox: function (value) {
      if (value == 0) {
        value = "No";
      } else if (value == 1) {
        value = "Yes";
      }
      return value;
    },
    async getData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/bibs");
        // JSON responses are automatically parsed.
        console.log(response);
        this.bibs = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getRacerData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/racers");
        // JSON responses are automatically parsed.
        console.log(response);
        this.racers = response.data;
        this.racersWithBibs = response.data.filter((item) => {
          return item.bib > 0;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getData();
    this.getDivisionData();
    this.getRacerData();
  },
  computed: {
    filteredBibs() {
      let tempBibs = this.bibs;
      let index = this.tab;

      tempBibs = tempBibs.filter((item) => {
        return item.allocated == this.tabs[index];
      });
      console.log(tempBibs);
      return tempBibs;
    },
    searchRacerfn() {
      let searchResults = this.racers;

      searchResults = searchResults.filter((item) => {
        return item.name.toLowerCase().includes(this.searchRacer.toLowerCase());
      });
      console.log(searchResults.slice(0, 7));
      return searchResults.slice(0, 7);
    },
    searchBibfn() {
      let searchResults = 0;

      searchResults = this.filteredBibs;

      if (this.tab == 0) {
        searchResults = searchResults.filter((item) => {
          return item.number
            .toLowerCase()
            .includes(this.overallSearch.toLowerCase());
        });
      } else if (this.tab == 2) {
        searchResults = this.bibs;
        searchResults = searchResults.filter((item) => {
          return item.number
            .toLowerCase()
            .includes(this.overallSearch.toLowerCase());
        });
      } else {
        searchResults = this.racersWithBibs;
        searchResults = searchResults.filter((item) => {
          return item.bib
            .toLowerCase()
            .includes(this.overallSearch.toLowerCase());
        });
      }
      //console.log(searchResults.slice(0, 7));
      return searchResults;
    },
  },
};
</script>

<style>
.avatarrow {
  display: flex;
  justify-content: center;
}
.vertBib {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
}
.vgt-wrap {
  max-width: 96%;
  min-width: 90%;
  justify-items: center;
  justify-items: center;
  justify-self: center;
  justify-content: centre;
}
</style>
