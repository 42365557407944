<template>
  <div class="teams">
    <div id="dataTable">
      <vue-good-table
        @on-row-click="onRowClick"
        @on-row-dblclick="onRowDblClick"
        :columns="columns"
        :rows="teams"
        :fixed-header="false"
        styleClass="vgt-table striped vgt-teams condensed"
        :select-options="{ enabled: false, disableSelectInfo: true }"
        :search-options="{ enabled: true, placeholder: 'Search Teams' }"
        :row-style-class="rowStyleClassFn"
        :pagination-options="{
          enabled: true,
          perPage: 25,
          mode: 'pages',
          perPageDropdownEnabled: false,
        }"
      >
        <div slot="table-actions-bottom">Double-click to edit.</div>
        <div slot="table-actions">
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-container class="grey lighten-5">
              <v-row dense align="center" align-content="center">
                <v-col cols="6" sm="6">
                  <v-dialog
                    v-model="editDialog"
                    persistent
                    max-width="800px"
                    min-width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        fab
                        color="accent"
                        :disabled="!showEdit"
                      >
                        <v-icon>mdi-shield-edit</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Edit Team</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="2" sm="2" md="2">
                              <v-text-field
                                label="Team ID"
                                required
                                readonly
                                v-model="editTeam.id"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2">
                              <v-text-field
                                label="Team Name"
                                required
                                max-length="1"
                                v-model="editTeam.name"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="8">
                              <v-autocomplete
                                v-model="editTeam.clubId"
                                :items="clubs"
                                item-text="name"
                                item-value="id"
                                label="Club"
                                :rules="[rules.required]"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-autocomplete
                                v-model="editTeam.divisionId"
                                :items="divisions"
                                item-text="name"
                                item-value="id"
                                label="Division"
                                :rules="[rules.required]"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6" md="8">
                              <v-text-field
                                label="Notes"
                                required
                                maxlength="20"
                                counter
                                v-model="editTeam.notes"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="2" sm="2" md="2">
                              <v-text-field
                                label="Males"
                                required
                                maxlength="1"
                                counter
                                v-model="editTeam.males"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2">
                              <v-text-field
                                label="Pros"
                                required
                                maxlength="1"
                                counter
                                v-model="editTeam.professionals"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="42" sm="2" md="2">
                              <v-text-field
                                label="Racers"
                                required
                                maxlength="20"
                                counter
                                v-model="editTeam.racers"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2">
                              <v-text-field
                                label="Time"
                                required
                                maxlength="20"
                                counter
                                v-model="editTeam.time"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                        <small>*indicates required field</small>
                      </v-card-text>
                      <v-data-table
                        dense
                        :headers="headers"
                        :items="teamMembers"
                        :items-per-page="6"
                        class="elevation-1"
                        mobile-breakpoint="10"
                        disable-pagination
                        disable-filtering
                        disable-sort
                        hide-default-footer
                        :loading="loadingMembers"
                        loading-text="Loading team members..."
                      >
                        <template v-slot:item.professional="{ item }">
                          <v-chip
                            v-if="item.professional == 1"
                            color="primary"
                            dark
                            x-small
                          >
                            PRO
                          </v-chip>
                        </template>
                      </v-data-table>
                      <v-card-actions>
                        <v-btn
                          color="blue darken-2"
                          text
                          @click="confirmDelete()"
                        >
                          Delete
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="cancelEditTeam()"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="submitEditTeam()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog></v-col
                >
              </v-row></v-container
            >
          </div>
        </div>
        <div slot="emptystate">No Teams found</div>
      </vue-good-table>
    </div>
    <v-snackbar v-model="errorPopup" timeout="2000" color="red darken-4">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="successPopup" timeout="2000" color="green">
      {{ successMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="successPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    ConfirmDlg: () => import("../components/ConfirmDlg"),
  },
  data() {
    return {
      errorMsg: "",
      errorPopup: false,
      showEdit: false,
      successMsg: "",
      successPopup: "",
      loading: true,
      loadingMembers: true,
      //Headers for Members in Team (edit Dialog)
      headers: [
        { text: "Bib", value: "bib" },
        { text: "Name", value: "name" },
        { text: "M/F", value: "gender" },
        { text: "", value: "professional" },
      ],
      teamMembers: [{}],
      editDialog: false,
      newDialog: false,

      divisions: [],
      confMsg: "",
      editTeam: { name: "" },
      rules: {
        required: (value) => !!value || "Required.",
      },

      clubs: [],

      teams: [],
      selectedTeam: null,
      columns: [
        {
          label: "ID",
          field: "id",
          hidden: true,
        },
        {
          label: "clubid",
          field: "clubid",
          hidden: true,
        },
        {
          label: "divisionid",
          field: "divisionid",
          hidden: true,
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Division",
          field: "division",
        },

        {
          label: "Club",
          field: "club",
        },

        {
          label: "Males",
          field: "males",
        },

        {
          label: "Pros",
          field: "professionals",
        },

        {
          label: "Racers",
          field: "racers",
        },

        {
          label: "Time",
          field: "time",
        },

        {
          label: "Notes",
          field: "notes",
        },
      ],
    };
  },

  methods: {
    rowStyleClassFn(row) {
      if (row.id == this.editTeam.id) {
        return "blue lighten-5";
      }
    },
    async confirmDelete() {
      var confMsg = "";
      confMsg = "Are you sure you want to delete this Team?";

      if (
        await this.$refs.confirm.open("Confirm", confMsg, {
          color: "red lighten-1",
        })
      ) {
        this.deleteTeam();
      }
    },
    async deleteTeam() {
      try {
        const response = await axios.delete(
          "https://dev.app1.rsc.org.nz/team/" + this.editTeam.id
        );
        // JSON responses are automatically parsed.
        console.log(response);
      } catch (error) {
        console.log(error);
        this.errorMsg = error;
        this.errorPopup = true;
      } finally {
        this.loading = false;
        this.editDialog = false;
        if (!this.errorPopup) {
          this.successMsg = "Team Deleted!";
          this.successPopup = true;
        }
        this.getData();
      }
    },

    async submiteditTeam() {
      try {
        const response = await axios.put("https://dev.app1.rsc.org.nz/teams", {
          id: this.editTeam.id,
          code: this.editTeam.code,
          name: this.editTeam.name,
        });
        // JSON responses are automatically parsed.
        console.log(response);
      } catch (error) {
        console.log(error);
        this.errorMsg = error;
        this.errorPopup = true;
      } finally {
        this.loading = false;
        this.editDialog = false;
        this.successMsg = "Success! Team edited!";
        this.successPopup = true;
        this.getData();
      }
    },
    cancelEditTeam() {
      this.editDialog = false;
    },

    onRowClick(params) {
      this.editTeam.name = params.row.name;
      this.editTeam.code = params.row.code;
      this.editTeam.id = params.row.id;
      //this.editTeam.club = params.row.club;
      this.editTeam.clubId = params.row.clubid;
      //this.editTeam.division = params.row.division;
      this.editTeam.divisionId = params.row.divisionid;
      this.showEdit = true;
    },

    onRowDblClick(params) {
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument
      // indicates selected or not
      // params.event - click event

      this.editTeam.name = params.row.name;
      //this.editTeam.division = params.row.division;
      //this.editTeam.club = params.row.club;
      this.editTeam.males = params.row.males;
      this.editTeam.professionals = params.row.professionals;
      this.editTeam.racers = params.row.racers;
      this.editTeam.time = params.row.time;
      this.editTeam.notes = params.row.notes;
      this.editTeam.id = params.row.id;
      this.getTeamMembers(this.editTeam.id);

      this.editDialog = true;
      console.log(params);
    },

    allocatedBox: function (value) {
      if (value == 0) {
        value = "No";
      } else if (value == 1) {
        value = "Yes";
      }
      return value;
    },
    async getData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/teams");
        // JSON responses are automatically parsed.
        console.log(response);
        this.teams = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getDivisions() {
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/divisions"
        );
        // JSON responses are automatically parsed.
        console.log(response);
        this.divisions = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getClubs() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/clubs");
        // JSON responses are automatically parsed.
        console.log(response);
        this.clubs = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getTeamMembers(teamid) {
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/racers/team/" + teamid.toString()
        );
        // JSON responses are automatically parsed.
        console.log(response);
        this.teamMembers = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingMembers = false;
      }
    },
  },
  mounted() {
    this.getData();
    this.getClubs();
    this.getDivisions();
  },
};
</script>

<style>
.vgt-wrap {
  max-width: 96%;
  min-width: 90%;
  justify-items: center;
  justify-items: center;
  justify-self: center;
  justify-content: centre;
}
</style>
