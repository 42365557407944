<template>
  <v-container fluid>
    <v-card class="my-0 ma-auto" max-width="850px">
      <v-simple-table dense>
        <thead>
          <tr>
            <th class="text-center smaller">Place</th>
            <th class="text-center smaller">Bib</th>
            <th class="text-center">Name</th>
            <th class="text-center smaller">Time</th>
            <th class="text-center">Club</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in results" :key="index">
            <td>{{ index +1 }}</td>
            <td>{{ result.BIB }}</td>
            <td>{{ result.NAME }}</td>
            <td>{{ result.TIME }}</td>
            <td>{{ result.CLUB }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ResultTable",
  props: {
    results: [Object, Array],
  },
  data() {
    return {};
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
th {
  width: 110px;
}
.smaller {
  width: 35px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
