<template>
  <v-container>
    <v-tabs
      v-model="tab"
      value="tabvalue"
      align-with-title
      background-color="teal lighten-4"
    >
      <v-tab @change="changeTab('byname')"> Start List by Name</v-tab>
      <v-tab @change="changeTab('bybib')"> Start List by Bib</v-tab>
      <v-tab @change="changeTab('byclub')"> Start List By Club</v-tab>
      <v-tab @change="changeTab('forclub')"> Start List for Club</v-tab>
      <v-tab-item
        ><v-data-table
          dense
          :headers="headers"
          :items="results"
          :items-per-page="200"
          class="elevation-1"
          mobile-breakpoint="10"
          disable-pagination
          disable-filtering
          disable-sort
          hide-default-footer
          :loading="loading"
        ></v-data-table>
      </v-tab-item>
      <v-tab-item
        ><v-data-table
          dense
          :headers="headers"
          :items="results"
          :items-per-page="200"
          class="elevation-1"
          mobile-breakpoint="10"
          disable-pagination
          disable-filtering
          disable-sort
          hide-default-footer
          :loading="loading"
        ></v-data-table>
      </v-tab-item>
      <v-tab-item
        ><v-data-table
          dense
          :headers="headers"
          :items="results"
          :items-per-page="200"
          class="elevation-1"
          mobile-breakpoint="10"
          disable-pagination
          disable-filtering
          disable-sort
          hide-default-footer
          :loading="loading"
        ></v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-autocomplete
            :items="clubs"
            item-text="name"
            item-value="id"
            label="Club"
            @change="fetchforclub($event)"
          >
          </v-autocomplete>
        </v-card>
        <v-data-table
          dense
          :headers="headersForClub"
          :items="results"
          :items-per-page="200"
          class="elevation-1"
          mobile-breakpoint="10"
          disable-pagination
          disable-filtering
          disable-sort
          hide-default-footer
          :loading="loading"
        ></v-data-table>
      </v-tab-item>
    </v-tabs>

    <v-snackbar v-model="errorPopup" timeout="2000" color="red darken-4">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      true: true,
      resul: [],
      loading: true,
      errorMsg: "",
      tab: 0,
      reqStatus: null,
      clubs: [],
      listName: "byname",
      errorPopup: false,
      headers: [
        { text: "Bib", value: "BIB" },
        { text: "Name", value: "NAME" },
        { text: "Gender", value: "GENDER" },
        { text: "Division", value: "DIVISION" },
        { text: "Club", value: "CLUB" },
      ],
      headersForClub: [
        { text: "Bib", value: "BIB" },
        { text: "Name", value: "NAME" },
        { text: "Gender", value: "GENDER" },
        { text: "Division", value: "DIVISION" },
      ],

      results: [
        {
          Bib: 12,
          Name: "dave",
          time: "23.22",
          div: "Open",
          club: "MattsClub",
        },
        {
          Bib: 12,
          Name: "dave",
          time: "23.22",
          div: "Open",
          club: "MattsClub",
        },
        {
          Bib: 12,
          Name: "dave",
          time: "23.22",
          div: "Open",
          club: "MattsClub",
        },
      ],
    };
  },
  methods: {
    fetchforclub(event) {
      console.log(event);
      let newName = "forclub/" + event.toString();
      this.getData(newName);
    },
    changeTab(name) {
      this.loading = true;
      this.results = [];
      if (name != "forclub") {
        this.getData(name);
      } else {
        this.loading = false;
      }
    },
    async getClubData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/clubs");
        // JSON responses are automatically parsed.
        console.log(response);
        this.clubs = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getData(listName) {
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/startlist/" + listName
        );
        // JSON responses are automatically parsed.
        //console.log("Matthere");
        //console.log(response);
        this.results = response.data;
        this.reqStatus = response;
      } catch (error) {
        console.log(error.toJSON());
        this.errorMsg = error.message;
        this.errorPopup = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getData(this.listName);
    this.getClubData();
  },
};
</script>

<style></style>
