<template>
  <div class="divisions">
    <div id="divisionTable">
      <vue-good-table
        :columns="columns"
        :rows="divisions"
        styleClass="vgt-table striped vgt-division"
      >
        <div slot="table-actions">
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <v-btn elevation="2" v-else disabled>Add Division</v-btn>
        </div>
        <div slot="emptystate">No Divisions found</div>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: true,
      divisions: [],
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "code",
          field: "code",
        },
        {
          label: "name",
          field: "name",
        },
        {
          label: "notes",
          field: "notes",
        },
      ],
    };
  },

  methods: {
    async getData() {
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/divisions"
        );
        // JSON responses are automatically parsed.
        console.log(response);
        this.divisions = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getData();
    console.log("hello");
  },
};
</script>

<style>
#divisionTable {
  align-items: center;
  display: flex;
  justify-content: center;
}
.vgt-wrap {
  max-width: 100%;
  min-width: 90%;
  justify-items: center;
  justify-items: center;
  justify-self: center;
  justify-content: centre;
}
</style>
