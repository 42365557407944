<template>
  <v-container
    ><div class="header-result">Haensli Cup 2022 - Results -  {{ route }} </div>

    <result-table v-if="!teamsResult" :results="results"></result-table>

    <div v-if="teamsResult" class="mb-2">
      <result-table
        :results="team"
        v-for="(team, index) in teamResults"
        :key="index"
      ></result-table>
    </div>
    <v-snackbar v-model="errorPopup" timeout="2000" color="red darken-4">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import ResultTable from "../components/ResultTable.vue";

export default {
  components: { ResultTable },
  data() {
    return {
      true: true,
      resultsSingle: [],
      teamResults: [],
      resul: [],
      loading: true,
      division: "",
      errorMsg: "",
      reqStatus: null,
      teams: false,
      teamsResult: false,
      errorPopup: false,
      route: "",
      headers: [
        { text: "Place", value: ""},
        { text: "Bib", value: "BIB" },
        { text: "Name", value: "NAME" },
        { text: "Time", value: "TIME" },
        { text: "Division", value: "a" },
        { text: "Club", value: "CLUB" },
      ],

      results: [],
    };
  },
  methods: {
    async getData(division) {
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/results/" + division
        );
        // JSON responses are automatically parsed.
        //console.log("Matthere");
        //console.log(response);
        this.results = response.data;
        this.resultsSingle = response.data;
        this.teamResults = response.data;
        this.reqStatus = response;
      } catch (error) {
        console.log(error.toJSON());
        this.errorMsg = error.message;
        this.errorPopup = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    let route = this.$route.params.division.toLowerCase();
    let split = route.split("teams");
    this.route = route;
    if (split.length == 2) {
      this.teamsResult = true;
      this.division = split[1].charAt(0).toUpperCase() + split[1].slice(1);
      this.getData("teams/" + split[1]);
      this.resultsSingle = [];
      this.teamResults = this.results;
    } else {
      this.getData(this.$route.params.division.toLowerCase());
      this.resultsSingle = this.results;
      this.teamResults = null;
    }
  },
};
</script>

<style scoped>


.header-result {
  text-transform: capitalize;
}
</style>
