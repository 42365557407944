<template>
  <div class="racers">
    <div id="dataTable">
      <vue-good-table
        ref="racerTable"
        @on-row-dblclick="onRowDblClick"
        @on-selected-rows-change="selectionChanged"
        :columns="columns"
        :rows="racers"
        :fixed-header="false"
        styleClass="vgt-table striped vgt-racers condensed"
        :select-options="{ enabled: true, disableSelectInfo: true }"
        :search-options="{
          enabled: true,
          placeholder: 'Search racers',
        }"
        :pagination-options="{
          enabled: true,
          perPage: 25,
          mode: 'pages',
          perPageDropdownEnabled: false,
        }"
      >
        <div slot="table-actions" data-app min-width="300px">
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-container class="grey lighten-5">
              <v-row dense align="center" align-content="center">
                <v-col cols="6" sm="6">
                  <v-dialog
                    v-model="editDialog"
                    persistent
                    max-width="800px"
                    min-width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        fab
                        color="accent"
                        :disabled="!showEdit"
                        @click="calcAgeAtDateEdit()"
                      >
                        <v-icon>mdi-account-edit</v-icon>
                      </v-btn>
                    </template>
                    <v-card
                      ><v-form ref="editRacerForm">
                        <v-card-title>
                          <span class="text-h5">Edit Racer</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="8">
                                <v-text-field
                                  label="Full Name"
                                  required
                                  v-model="editRacer.name"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-select
                                  :items="genders"
                                  label="Gender"
                                  v-model="editRacer.gender"
                                  :rules="[rules.required]"
                                ></v-select>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  label="Email"
                                  
                                  v-model="editRacer.email"
                                  
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  label="Phone"
                                  
                                  dense
                                  v-model="editRacer.phone"
                                  
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                  v-model="editRacer.club"
                                  :items="clubs"
                                  item-text="name"
                                  item-value="id"
                                  label="Club"
                                  :rules="[rules.required]"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                  v-model="editRacer.division"
                                  :items="divisions"
                                  item-text="name"
                                  item-value="id"
                                  label="Division"
                                  :rules="[rules.required]"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-menu
                                  ref="editDOBMenu"
                                  v-model="editDOBMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editRacer.dob"
                                      label="DOB"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :hint="dobHint"
                                      persistent-hint
                                      :rules="[rules.required]"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="editRacer.dob"
                                    :active-picker.sync="activeEditDOBPicker"
                                    :max="
                                      new Date(
                                        Date.now() -
                                          new Date().getTimezoneOffset() * 60000
                                      )
                                        .toISOString()
                                        .substr(0, 10)
                                    "
                                    min="1930-01-01"
                                    @change="saveEditDOB"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="24" sm="6">
                                <v-textarea
                                  name="notes"
                                  outlined
                                  v-model="editRacer.notes"
                                  label="Notes"
                                  prepend-icon="mdi-comment"
                                  rows="1"
                                >
                                </v-textarea>
                              </v-col>
                              <v-col>
                                <v-container
                                  ><v-row>
                                    <v-col cols="24" sm="6">
                                      <v-radio-group
                                        v-model="editRacer.professional"
                                        row
                                        mandatory
                                      >
                                        <v-radio
                                          label="Amateur"
                                          value="false"
                                        />
                                        <v-radio
                                          label="Professional"
                                          value="true"
                                        />
                                      </v-radio-group>
                                    </v-col> </v-row
                                ></v-container>
                              </v-col>
                              <v-row> </v-row> </v-row
                            ><v-row>
                              <v-col>
                                <v-checkbox v-model="editRacer.dnf" label="DNF">
                                </v-checkbox>
                              </v-col>
                              <v-col>
                                <v-checkbox v-model="editRacer.dsq" label="DSQ">
                                </v-checkbox>
                              </v-col>
                              <v-col cols="4" sm="4">
                                <v-select
                                  v-model="editRacer.team"
                                  :items="editRacerTeams"
                                  item-text="name"
                                  item-value="id"
                                  label="Team"
                                  :rules="[rules.required]"
                                ></v-select>
                              </v-col>
                              <v-col cols="4" sm="4" md="4">
                                <v-text-field
                                  label="Time"
                                  required
                                  v-model="editRacer.time"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4" sm="4" md="4">
                                <v-text-field
                                  label="Bib"
                                  required
                                  type="number"
                                  v-model.number="editRacer.bib"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                          <small>*indicates required field</small>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="cancelEditRacer()"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="submitEditRacer()"
                          >
                            Save
                          </v-btn>
                        </v-card-actions></v-form
                      >
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        fab
                        color="accent"
                        :disabled="!showDelete"
                        @click="confirmDelete()"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-account-minus</v-icon>
                      </v-btn>
                    </template>
                    <span> Delete Racer </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6">
                  <v-dialog v-model="dialog" persistent max-width="750px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small fab color="accent">
                        <v-icon>mdi-account-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">New Racer</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form ref="newRacerForm">
                            <v-row>
                              <v-col cols="12" sm="6" md="8">
                                <v-text-field
                                  label="Full Name"
                                  required
                                  v-model="newRacerName"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-select
                                  :items="genders"
                                  label="Gender"
                                  v-model="newRacerGender"
                                  :rules="[rules.required]"
                                ></v-select>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  label="Email*"
                                  required
                                  v-model="newRacerEmail"
                                  :rules="[rules.required, rules.email]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  label="Phone*"
                                  required
                                  v-model="newRacerPhone"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                  v-model="newRacerClub"
                                  :items="clubs"
                                  item-text="name"
                                  item-value="id"
                                  label="Club"
                                  :rules="[rules.required]"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                  v-model="newRacerDivision"
                                  :items="divisions"
                                  item-text="name"
                                  item-value="id"
                                  label="Division"
                                  :rules="[rules.required]"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="newRacerDOB"
                                      label="DOB"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :hint="dobHint"
                                      persistent-hint
                                      :rules="[rules.required]"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="newRacerDOB"
                                    :active-picker.sync="activePicker"
                                    :max="
                                      new Date(
                                        Date.now() -
                                          new Date().getTimezoneOffset() * 60000
                                      )
                                        .toISOString()
                                        .substr(0, 10)
                                    "
                                    min="1930-01-01"
                                    @change="save"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="24" sm="6">
                                <v-textarea
                                  name="notes"
                                  outlined
                                  v-model="newRacerNotes"
                                  label="Notes"
                                  prepend-icon="mdi-comment"
                                  rows="1"
                                >
                                </v-textarea>
                              </v-col>
                              <v-col cols="24" sm="6">
                                <v-radio-group
                                  v-model="newRacerProfessional"
                                  row
                                  mandatory
                                >
                                  <v-radio label="Amateur" value="false" />
                                  <v-radio label="Professional" value="true" />
                                </v-radio-group>
                              </v-col> </v-row
                          ></v-form>
                        </v-container>
                        <small>*indicates required field</small>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="cancelNewRacer()"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="submitNewRacer()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-btn small color="accent" fab @click="getData()">
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
        <div slot="emptystate">No Racers found</div>
      </vue-good-table>
    </div>

    <v-snackbar v-model="errorPopup" timeout="2000" color="red darken-4">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="successPopup" timeout="2000" color="green">
      {{ successMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="successPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    ConfirmDlg: () => import("../components/ConfirmDlg"),
    //BtnDeleteRacer: () => import("../components/BtnDeleteRacer"),
  },
  data() {
    return {
      loading: true,
      mobile: false,
      activePicker: null,
      activeEditDOBPicker: null,
      menu: false,
      editDOBMenu: false,
      dialog: false,
      editDialog: false,
      editRacerTeams: [],
      editRacer: {
        id: 0,
        name: "",
        email: "",
        gender: "",
        phone: "",
        club: "",
        bib: null,
        division: "",
        dnf: false,
        dsq: false,
        notes: "",
        team: "0",
        time: "",
        updated: "",
        dob: "",
        professional: null,
      },
      selectedRows: [],
      genders: ["M", "F"],
      newRacerClub: "",
      newRacerDivision: "",
      newRacerGender: "",
      newRacerPhone: "",
      newRacerEmail: "",
      newRacerNotes: "",
      divLabel: "Division",
      newRacerName: "",
      newRacerDOB: "",
      dobHint: "",
      showEdit: false,
      showDelete: false,
      newRacerProfessional: "false",
      divisions: [],
      racers: [],
      clubs: [],
      teams: [{ name: "Noned", id: 0 }],
      errorMsg: "",
      errorPopup: false,
      successMsg: "",
      successPopup: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
          hidden: true,
        },
        {
          label: "Bib",
          field: "bib",
          type: "number",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "DOB",
          field: "dob",
          hidden: true,
        },
        {
          label: "M/F",
          field: "gender",
        },
        {
          label: this.divLabel,
          field: "division",
          filterOptions: {
            enabled: false,
            //filterDropdownItems: ["Open", "Junior", "Masters", "Snowboard"],
            //TODO: this needs to be populated from the this.divisions.
          },
        },
        {
          label: "Club",
          field: "club",
        },
        {
          label: "Notes",
          field: "notes",
          hidden: this.$vuetify.breakpoint.xs,
        },
      ],
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.newRacerDOB);
    },
    computedDateFormattedEdit() {
      return this.formatDate(this.editRacer.dob);
    },
    mobilecomputed() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    menu(val) {
      //resets the DOB picker in the new racer form back to Year.
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    editDOBMenu(val) {
      //Resets the DOB picker in the edit racer form.
      val && setTimeout(() => (this.activeEditDOBPicker = "YEAR"));
    },
    newRacerDOB(val) {
      if (val) {
        this.dateFormatted = this.formatDate(this.newRacerDOB);
      }
      this.dateFormatted = this.formatDate(this.newRacerDOB);
    },
  },
  methods: {
    onRowDblClick(params) {
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument
      // indicates selected or not
      // params.event - click event
      //this.itemsWithClub();

      this.editRacer.name = params.row.name;
      this.editRacer.email = params.row.email;
      this.editRacer.gender = params.row.gender;
      this.editRacer.phone = params.row.phone;
      this.editRacer.id = params.row.id;
      this.editRacer.club = this.clubs.find(
        (x) => x.name == params.row.club
      ).id;
      this.editRacer.bib = params.row.bib;
      this.editRacer.division = this.divisions.find(
        (x) => x.name == params.row.division
      ).id;
      this.editRacer.professional = params.row.professional;
      if (params.row.dnf == 1) {
        this.editRacer.dnf = true;
      } else {
        this.editRacer.dnf = false;
      }

      if (params.row.dsq == 1) {
        this.editRacer.dsq = true;
      } else {
        this.editRacer.dsq = false;
      }
      this.editRacer.notes = params.row.notes;
      this.editRacer.team = params.row.team;

      this.getTeamByClubDivision(this.editRacer.division, this.editRacer.club);
      console.log("FilteredTeams are : " + this.editRacerTeams);
      //this.editRacer.team = this.teams.find(
      //  (x) => x.name == params.row.team
      //).id;
      console.log("Looked up team is: " + this.editRacer.team);

      this.editRacer.time = params.row.time;
      this.editRacer.updated = params.row.updated;
      this.editRacer.dob = params.row.dob;
      this.editDialog = true;
      console.log("Double Clicked");
    },

    getDivLabel() {
      if (this.$vuetify.breakpoint.xs) {
        this.divLabel = "Div";
      } else {
        this.divLabel = "Division";
      }
    },
    async confirmDelete() {
      var confMsg = "";
      if (this.$refs["racerTable"].selectedRows.length == 1) {
        confMsg = "Are you sure you want to delete this racer?";
      } else {
        confMsg = "You are about to delete more than one racer! Are you sure?";
      }
      if (
        await this.$refs.confirm.open("Confirm", confMsg, {
          color: "red darken-2",
        })
      ) {
        this.deleteRacer();
      }
    },
    async deleteRacer() {
      if (this.$refs["racerTable"].selectedRows.length == 1) {
        console.log(this.$refs["racerTable"].selectedRows);
        try {
          const racerToDelete = this.$refs["racerTable"].selectedRows[0];
          const response = await axios.delete(
            "https://dev.app1.rsc.org.nz/racers/" + racerToDelete.id
          );
          // JSON responses are automatically parsed.
          console.log(response);
          //this.racers = response.data;
        } catch (error) {
          console.log(error);
          this.errorMsg = error;
          this.errorPopup = true;
        } finally {
          this.loading = false;
          this.successMsg = "Success! Racer Removed";
          this.successPopup = true;
          this.getData();
        }
      }
    },
    async submitNewRacer() {
      if (this.$refs.newRacerForm.validate()) {
        try {
          const newRacer = {
            name: this.newRacerName,
            dob: this.newRacerDOB,
            gender: this.newRacerGender,
            professional: this.newRacerProfessional,
            email: this.newRacerEmail,
            phone: this.newRacerPhone,
            club: this.newRacerClub,
            division: this.newRacerDivision,
            notes: this.newRacerNotes,
          };
          const response = await axios.post(
            "https://dev.app1.rsc.org.nz/racers",
            newRacer
          );
          // JSON responses are automatically parsed.
          console.log(response);
          //this.racers = response.data;
        } catch (error) {
          console.log(error);
          this.errorMsg = error;
          this.errorPopup = true;
        } finally {
          this.loading = false;
          this.cancelNewRacer();
          if (!this.errorPopup) {
            this.successMsg = "Success! New Racer Added!";
            this.successPopup = true;
            this.getData();
          }
        }
      }
    },
    cancelEditRacer() {
      this.editDialog = false;
    },
    async submitEditRacer() {
      if (this.$refs.editRacerForm.validate()) {
        try {
          const editRacer = {
            name: this.editRacer.name,
            dob: this.editRacer.dob,
            gender: this.editRacer.gender,
            professional: this.editRacer.professional,
            email: this.editRacer.email,
            phone: this.editRacer.phone,
            club: this.editRacer.club,
            division: this.editRacer.division,
            notes: this.editRacer.notes,
            id: this.editRacer.id,
            dnf: this.editRacer.dnf,
            dsq: this.editRacer.dsq,
            time: this.editRacer.time,
            bib: this.editRacer.bib,
            team: this.editRacer.team,
          };
          if (editRacer.bib == "None") {
            editRacer.bib = null;
          }
          if (editRacer.team == "None") {
            editRacer.team = null;
          }
          if (editRacer.dnf == true) {
            editRacer.dnf = 1;
          } else {
            editRacer.dnf = 0;
          }
          if (editRacer.dsq == true) {
            editRacer.dsq = 1;
          } else {
            editRacer.dsq = 0;
          }
          console.log("ZZTOP: " + editRacer);
          const response = await axios.put(
            "https://dev.app1.rsc.org.nz/racer",
            editRacer
          );
          // JSON responses are automatically parsed.
          console.log(response);
          //this.racers = response.data;
        } catch (error) {
          console.log(error);
          this.errorMsg = error;
          this.errorPopup = true;
        } finally {
          this.loading = false;
          this.cancelNewRacer();
          if (!this.errorPopup) {
            this.successMsg = "Success! Racer updated!";
            this.successPopup = true;
            this.editDialog = false;
          }
          this.getData();
        }
      }
    },
    cancelNewRacer() {
      // Close the new racer dialog.
      this.dialog = false;
      //Empty the values that may have been entered so they don't reappear.
      this.newRacerClub = "";
      this.newRacerDivision = "";
      this.newRacerGender = "";
      this.newRacerPhone = "";
      this.newRacerEmail = "";
      this.newRacerNotes = "";
      this.newRacerName = "";
      this.newRacerDOB = "";
      this.dobHint = "";
    },
    selectionChanged: function (event) {
      //Watch for the rows being selected in the table.
      //Adjust the Edit, Delete buttons to suit the logic (cant edit more than 1 racer, cant delete 0 racers)
      console.log(event);
      if (event.selectedRows.length == 0 || event.selectedRows.length > 1) {
        this.showEdit = false;
      }
      if (event.selectedRows.length == 1) {
        this.showEdit = true;
        this.showDelete = true;
        console.log("Selected row below here");
        console.log(this.$refs["racerTable"].selectedRows[0]);
        this.editRacer.name = this.$refs["racerTable"].selectedRows[0].name;
        this.editRacer.email = this.$refs["racerTable"].selectedRows[0].email;
        this.editRacer.gender = this.$refs["racerTable"].selectedRows[0].gender;
        this.editRacer.phone = this.$refs["racerTable"].selectedRows[0].phone;
        this.editRacer.id = this.$refs["racerTable"].selectedRows[0].id;
        this.editRacer.club = this.clubs.find(
          (x) => x.name == this.$refs["racerTable"].selectedRows[0].club
        ).id;

        this.editRacer.bib = this.$refs["racerTable"].selectedRows[0].bib;
        this.editRacer.division = this.divisions.find(
          (x) => x.name == this.$refs["racerTable"].selectedRows[0].division
        ).id;
        this.editRacer.professional =
          this.$refs["racerTable"].selectedRows[0].professional;
        this.editRacer.dnf = this.$refs["racerTable"].selectedRows[0].dnf;
        this.editRacer.dsq = this.$refs["racerTable"].selectedRows[0].dsq;
        this.editRacer.notes = this.$refs["racerTable"].selectedRows[0].notes;

        this.editRacer.team = this.$refs["racerTable"].selectedRows[0].team;
        //this.editRacer.team = this.teams.find(
        // (x) => x.name == this.$refs["racerTable"].selectedRows[0].team
        //).id;

        this.editRacer.time = this.$refs["racerTable"].selectedRows[0].time;
        this.editRacer.updated =
          this.$refs["racerTable"].selectedRows[0].updated;
        this.editRacer.dob = this.$refs["racerTable"].selectedRows[0].dob;
      }
      if (event.selectedRows.length >= 1) {
        this.showDelete = true;
      }
      if (event.selectedRows.length == 0) {
        this.showDelete = false;
        this.showEdit = false;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    calcAgeAtDate() {
      var dob_parts = this.computedDateFormatted.split("/");
      var year = new Date().getFullYear();
      var today = new Date();
      var hint =
        today.getFullYear() -
        dob_parts[2] -
        ("0701" < dob_parts[1] + dob_parts[0]) +
        " as at 1/7/" +
        year;
      this.dobHint = hint;
      return hint;
    },
    calcAgeAtDateEdit() {
      var dob_parts = this.computedDateFormattedEdit.split("/");
      var year = new Date().getFullYear();
      var today = new Date();
      var hint =
        today.getFullYear() -
        dob_parts[2] -
        ("0701" < dob_parts[1] + dob_parts[0]) +
        " as at 1/7/" +
        year;
      this.dobHint = hint;
      return hint;
    },
    save(newRacerDOB) {
      this.$refs.menu.save(newRacerDOB);
      this.calcAgeAtDate();
    },
    saveEditDOB(editRacerdob) {
      this.$refs.editDOBMenu.save(editRacerdob);
      this.calcAgeAtDateEdit();
    },
    allocatedBox: function (value) {
      if (value == 0) {
        value = "No";
      } else if (value == 1) {
        value = "Yes";
      }
      return value;
    },
    async getData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/racers");
        // JSON responses are automatically parsed.
        console.log(response);
        this.racers = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getClubData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/clubs");
        // JSON responses are automatically parsed.
        console.log(response);
        this.clubs = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getDivisionData() {
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/divisions"
        );
        // JSON responses are automatically parsed.
        console.log(response);
        this.divisions = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getTeamData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/teams");
        // JSON responses are automatically parsed.
        console.log(response);
        this.teams = response.data;
        this.teams.unshift({ name: "None.", id: "0" });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getTeamByClubDivision(division, club) {
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/teams/division/club",
          { params: { division, club } }
        );
        // JSON responses are automatically parsed.
        console.log(response);
        this.editRacerTeams = response.data;
        this.editRacerTeams.unshift({ name: "None.", id: "0" });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getData();
    this.getClubData();
    this.getDivisionData();
    this.getDivLabel();
    this.getTeamData();
  },
};
</script>

<style>
.vgt-wrap {
  max-width: 96%;
  min-width: 90%;
  justify-items: center;
  justify-items: center;
  justify-self: center;
  justify-content: centre;
}
.vgt-global-search__input {
  position: relative;
  padding-left: 40px;
  flex-grow: 0.95;
}
.racers {
  margin-top: 20px;
}
</style>
