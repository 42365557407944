<template>
  <div class="bibs">
    <div id="dataTable">
      <vue-good-table
        @on-row-click="onRowClick"
        @on-row-dblclick="onRowDblClick"
        :columns="columns"
        :rows="clubs"
        :fixed-header="true"
        styleClass="vgt-table striped vgt-clubs condensed"
        :select-options="{ enabled: false, disableSelectInfo: true }"
        :search-options="{ enabled: true, placeholder: 'Search Clubs' }"
        :row-style-class="rowStyleClassFn"
        :pagination-options="{
          enabled: true,
          perPage: 25,
          mode: 'pages',
          perPageDropdownEnabled: false,
        }"
      >
        <div slot="table-actions-bottom">Double-click to edit.</div>
        <div slot="table-actions">
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-container class="grey lighten-5">
              <v-row dense align="center" align-content="center">
                <v-col cols="6" sm="6">
                  <v-dialog
                    v-model="editDialog"
                    persistent
                    max-width="800px"
                    min-width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        fab
                        color="accent"
                        :disabled="!showEdit"
                      >
                        <v-icon>mdi-shield-edit</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Edit Club</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="2" sm="2" md="2">
                              <v-text-field
                                label="Club ID"
                                required
                                readonly
                                v-model="editClub.id"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="8">
                              <v-text-field
                                label="Club Code"
                                required
                                v-model="editClub.code"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="8">
                              <v-text-field
                                label="Club Name"
                                required
                                maxlength="20"
                                counter
                                v-model="editClub.name"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                        <small>*indicates required field</small>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="blue darken-2"
                          text
                          @click="confirmDelete()"
                        >
                          Delete
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="cancelEditClub()"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="submitEditClub()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-dialog
                    v-model="newDialog"
                    persistent
                    max-width="800px"
                    min-width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small fab color="accent">
                        <v-icon>mdi-shield-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-card
                      ><v-form ref="newClubForm">
                        <v-card-title>
                          <span class="text-h5">Add New Club</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="8">
                                <v-text-field
                                  label="Club Code*"
                                  required
                                  maxlength="4"
                                  counter
                                  v-model="newClub.code"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="8">
                                <v-text-field
                                  label="Club Name*"
                                  maxlength="20"
                                  counter
                                  required
                                  v-model="newClub.name"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                          <small>*indicates required field</small>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="newDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="submitNewClub()"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-form></v-card
                    >
                  </v-dialog></v-col
                ></v-row
              ></v-container
            >
          </div>
        </div>
        <div slot="emptystate">No Clubs found</div>
      </vue-good-table>
    </div>
    <v-snackbar v-model="errorPopup" timeout="2000" color="red darken-4">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="successPopup" timeout="2000" color="green">
      {{ successMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="successPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    ConfirmDlg: () => import("../components/ConfirmDlg"),
  },
  data() {
    return {
      errorMsg: "",
      errorPopup: false,
      showEdit: false,
      successMsg: "",
      successPopup: "",
      loading: true,
      editDialog: false,
      newDialog: false,
      newClub: {},
      confMsg: "",
      editClub: { name: "" },
      rules: {
        required: (value) => !!value || "Required.",
      },

      clubs: [],
      selectedClub: null,
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Code",
          field: "code",
        },
        {
          label: "Name",
          field: "name",
        },
      ],
    };
  },

  methods: {
    rowStyleClassFn(row) {
      if (row.id == this.editClub.id) {
        return "blue lighten-5";
      }
    },
    async confirmDelete() {
      var confMsg = "";
      confMsg = "Are you sure you want to delete this Club?";

      if (
        await this.$refs.confirm.open("Confirm", confMsg, {
          color: "red lighten-1",
        })
      ) {
        this.deleteClub();
      }
    },
    async deleteClub() {
      try {
        const response = await axios.delete(
          "https://dev.app1.rsc.org.nz/clubs/" + this.editClub.id
        );
        // JSON responses are automatically parsed.
        console.log(response);
      } catch (error) {
        console.log(error);
        this.errorMsg = error;
        this.errorPopup = true;
      } finally {
        this.loading = false;
        this.editDialog = false;
        if (!this.errorPopup) {
          this.successMsg = "Club Deleted!";
          this.successPopup = true;
        }
        this.getData();
      }
    },
    async submitNewClub() {
      if (this.$refs.newClubForm.validate()) {
        try {
          const response = await axios.post(
            "https://dev.app1.rsc.org.nz/clubs",
            {
              code: this.newClub.code,
              name: this.newClub.name,
            }
          );
          // JSON responses are automatically parsed.
          console.log(response);
        } catch (error) {
          console.log(error);
          this.errorMsg = error;
          this.errorPopup = true;
        } finally {
          this.loading = false;
          this.newDialog = false;
          if (!this.errorPopup) {
            this.successMsg = "Success! New Club added!";
            this.successPopup = true;
          }
          this.getData();
        }
      }
    },
    async submitEditClub() {
      try {
        const response = await axios.put("https://dev.app1.rsc.org.nz/clubs", {
          id: this.editClub.id,
          code: this.editClub.code,
          name: this.editClub.name,
        });
        // JSON responses are automatically parsed.
        console.log(response);
      } catch (error) {
        console.log(error);
        this.errorMsg = error;
        this.errorPopup = true;
      } finally {
        this.loading = false;
        this.editDialog = false;
        this.successMsg = "Success! Club edited!";
        this.successPopup = true;
        this.getData();
      }
    },
    cancelEditClub() {
      this.editDialog = false;
    },
    cancelNewClub() {
      this.NewDialog = false;
      this.newClub = {};
    },
    onRowClick(params) {
      this.editClub.name = params.row.name;
      this.editClub.code = params.row.code;
      this.editClub.id = params.row.id;
      this.showEdit = true;
    },

    onRowDblClick(params) {
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument
      // indicates selected or not
      // params.event - click event
      this.editClub.name = params.row.name;
      this.editClub.code = params.row.code;
      this.editClub.id = params.row.id;
      this.editDialog = true;
      console.log(params);
    },

    allocatedBox: function (value) {
      if (value == 0) {
        value = "No";
      } else if (value == 1) {
        value = "Yes";
      }
      return value;
    },
    async getData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/clubs");
        // JSON responses are automatically parsed.
        console.log(response);
        this.clubs = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.vgt-wrap {
  max-width: 96%;
  min-width: 90%;
  justify-items: center;
  justify-items: center;
  justify-self: center;
  justify-content: centre;
}
</style>
