var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bibs"},[_c('div',{attrs:{"id":"dataTable"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.clubs,"fixed-header":true,"styleClass":"vgt-table striped vgt-clubs condensed","select-options":{ enabled: false, disableSelectInfo: true },"search-options":{ enabled: true, placeholder: 'Search Clubs' },"row-style-class":_vm.rowStyleClassFn,"pagination-options":{
        enabled: true,
        perPage: 25,
        mode: 'pages',
        perPageDropdownEnabled: false,
      }},on:{"on-row-click":_vm.onRowClick,"on-row-dblclick":_vm.onRowDblClick}},[_c('div',{attrs:{"slot":"table-actions-bottom"},slot:"table-actions-bottom"},[_vm._v("Double-click to edit.")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[(_vm.loading)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',[_c('v-container',{staticClass:"grey lighten-5"},[_c('v-row',{attrs:{"dense":"","align":"center","align-content":"center"}},[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","min-width":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","color":"accent","disabled":!_vm.showEdit}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-edit")])],1)]}}]),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Edit Club")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"label":"Club ID","required":"","readonly":"","rules":[_vm.rules.required]},model:{value:(_vm.editClub.id),callback:function ($$v) {_vm.$set(_vm.editClub, "id", $$v)},expression:"editClub.id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-text-field',{attrs:{"label":"Club Code","required":"","rules":[_vm.rules.required]},model:{value:(_vm.editClub.code),callback:function ($$v) {_vm.$set(_vm.editClub, "code", $$v)},expression:"editClub.code"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-text-field',{attrs:{"label":"Club Name","required":"","maxlength":"20","counter":"","rules":[_vm.rules.required]},model:{value:(_vm.editClub.name),callback:function ($$v) {_vm.$set(_vm.editClub, "name", $$v)},expression:"editClub.name"}})],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-2","text":""},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" Delete ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.cancelEditClub()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.submitEditClub()}}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","min-width":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","color":"accent"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-plus")])],1)]}}]),model:{value:(_vm.newDialog),callback:function ($$v) {_vm.newDialog=$$v},expression:"newDialog"}},[_c('v-card',[_c('v-form',{ref:"newClubForm"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Add New Club")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-text-field',{attrs:{"label":"Club Code*","required":"","maxlength":"4","counter":"","rules":[_vm.rules.required]},model:{value:(_vm.newClub.code),callback:function ($$v) {_vm.$set(_vm.newClub, "code", $$v)},expression:"newClub.code"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-text-field',{attrs:{"label":"Club Name*","maxlength":"20","counter":"","required":"","rules":[_vm.rules.required]},model:{value:(_vm.newClub.name),callback:function ($$v) {_vm.$set(_vm.newClub, "name", $$v)},expression:"newClub.name"}})],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.newDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.submitNewClub()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)],1)],1)]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("No Clubs found")])])],1),_c('v-snackbar',{attrs:{"timeout":"2000","color":"red darken-4"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.errorPopup = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.errorPopup),callback:function ($$v) {_vm.errorPopup=$$v},expression:"errorPopup"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]),_c('v-snackbar',{attrs:{"timeout":"2000","color":"green"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"green","text":""},on:{"click":function($event){_vm.successPopup = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.successPopup),callback:function ($$v) {_vm.successPopup=$$v},expression:"successPopup"}},[_vm._v(" "+_vm._s(_vm.successMsg)+" ")]),_c('ConfirmDlg',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }