<template>
  <v-container>
    <v-layout fluid>
      <v-dialog
        v-model="editDialog"
        persistent
        max-width="800px"
        min-width="350px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Edit Time</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2" sm="2" md="2">
                  <v-text-field
                    label="Time ID"
                    required
                    readonly
                    v-model="editTime.id"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    label="Bib"
                    required
                    v-model="editTime.bib"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    label="Time"
                    required
                    maxlength="20"
                    counter
                    v-model="editTime.seconds"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-2" text @click="confirmDelete()">
              Delete
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelEditTime()">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitEditTime()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showMatchingError"
        persistent
        max-width="800px"
        min-width="350px"
      >
        <v-card>
          <v-card-title>
            There is already a time entered for this bib!</v-card-title
          >
          <v-card-text
            >{{ matchingTime.name }}
            {{ matchingTime.bib }}
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showMatchingError = false"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col sm="12" lg="12">
          <v-card :loading="submitting" @dblclick="submitting = false">
            <v-card-title>Add New Time </v-card-title>
            <v-card-text>
              <v-layout>
                <v-row>
                  <v-col cols="3" sm="4">
                    <v-text-field
                      label="Bib"
                      hint="number"
                      type="number"
                      v-model.number="newTime.bib"
                      @change="checkIfTimeAlreadyExists"
                    >
                    </v-text-field>
                  </v-col>
                                    <v-col cols="1" sm="1">
                    <v-text-field
                      label="Time Minutes"
                      hint="minutes"
                      type="number"
                      v-model.number="newTime.minutes"
                    >
                      s</v-text-field
                    >
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-text-field
                      label="Time Seconds"
                      hint="seconds"
                      type="number"
                      v-model.number="newTime.seconds"
                    >
                      s</v-text-field
                    >
                  </v-col>
                                    <v-col cols="1" sm="1">
                    <v-text-field
                      label="Time Calculated"
                      hint="seconds"
                      type="number"
                      :disabled="true"
                      v-model.number="newTimeConvertedToSeconds"
                    >
                      s</v-text-field
                    >
                  </v-col>
                  <v-col cols="3" sm="2">
                    <v-checkbox label="DNF" v-model="newTime.dnf"></v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="2">
                    <v-checkbox label="DSQ" v-model="newTime.dsq"></v-checkbox>
                  </v-col>
                </v-row>
              </v-layout>
              <v-row class="mt-2">
                <v-chip
                  class="mx-2 ml-4"
                  color="accent"
                  label
                  v-if="findRacer.length != 0"
                >
                  <v-icon left>mdi-division </v-icon>{{ findRacer[0].division }}
                </v-chip>
                <v-chip
                  color="accent"
                  class="mx-2"
                  label
                  v-if="findRacer.length != 0"
                >
                  <v-icon left>mdi-shield-home</v-icon>
                  {{ findRacer[0].club }}
                </v-chip>
                <v-chip
                  color="accent"
                  class="mx-2"
                  label
                  v-if="findRacer.length != 0"
                  ><v-icon left>mdi-account-circle-outline</v-icon
                  >{{ findRacer[0].name }}
                </v-chip>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                :disabled="disableSubmit"
                @click="submitTime()"
                >Submit</v-btn
              ><v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col sm="12" lg="12">
          <v-card>
            <v-data-table
              dense
              :headers="headers"
              :items="results"
              :items-per-page="200"
              class="elevation-1"
              mobile-breakpoint="10"
              disable-pagination
              hide-default-footer
              :loading="loading"
            >
              <template v-slot:item.dnf="{ item }">
                <v-chip v-if="item.dnf == 1" color="orange darken">
                  DNF
                </v-chip>
                <v-chip v-if="item.dsq == 1" color="red"> DSQ </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>

    <v-snackbar v-model="errorPopup" timeout="2000" color="red darken-4">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      true: true,
      resul: [],
      editDialog: null,
      showMatchingError: false,
      disableSubmit: false,
      matchingTime: [],
      racers: [],
      showEdit: false,
      loading: true,
      errorMsg: "",
      reqStatus: null,
      errorPopup: false,
      submitting: false,
      editTime: {},
      newTime: {
        minutes: 0,
        seconds: 0,
        dnf: false,
        dsq: false,
      },
      headers: [
        { text: "Bib", value: "bib" },
        { text: "Time", value: "time" },
        { text: "", value: "dnf", hidden: true },
        { text: "Notes", value: "notes" },
        { text: "Name", value: "name" },
        { text: "Division", value: "division" },
        { text: "Club", value: "club" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      results: [],
    };
  },
  computed: {
    newTimeConvertedToSeconds() {
    var min = this.newTime.minutes;
    var sec = this.newTime.seconds;
    var total = min * 60 + sec;
    return total;
    },

    
    findRacer() {
      return this.racers.filter((racer) => {
        return racer.bib.includes(this.newTime.bib);
      });
    },
    findTime() {
      return this.results.filter((results) => {
        return results.bib.includes(this.newTime.bib);
      });
    },
  },
  methods: {
    checkIfTimeAlreadyExists() {
      if (
        typeof this.results.find((x) => x.bib == this.newTime.bib) ==
        "undefined"
      ) {
        console.log("There was nobody home");
        this.disableSubmit = false;
      } else {
        this.matchingTime = this.results.find((x) => x.bib == this.newTime.bib);
        this.showMatchingError = true;
        this.disableSubmit = true;
      }
    },
async submitTime() {
  if (this.newTime.dnf == true) {
    //submit DNF /DSQ
            try {
        this.submitting = true;
        const response = await axios.post("https://dev.app1.rsc.org.nz/dnf/" + this.newTime.bib);
        // JSON responses are automatically parsed.
        console.log(response);
      if (response.status == 200) {
          this.submitting = false;
          this.getData();
        }
      } catch (error) {
        console.log(error);
        console.log ("There was an error")
      } finally {
        this.submitting = false;
      }
  } else if (this.newTime.dsq == true) {
          try {
        this.submitting = true;
        const response = await axios.post("https://dev.app1.rsc.org.nz/dsq/" + this.newTime.bib );
        // JSON responses are automatically parsed.
        console.log(response);
          if (response.status == 200) {
          this.submitting = false;
          this.getData();
        }
      } catch (error) {
        console.log(error);
        console.log ("There was an error2")
      } finally {
        this.submitting = false;
      }
  
  } else {
      try {
        this.submitting = true;
        const response = await axios.post("https://dev.app1.rsc.org.nz/time/" + this.newTime.bib + "/" + this.newTimeConvertedToSeconds);
        // JSON responses are automatically parsed.
        console.log(response);
        //this.racers = response.data;
        if (response.status == 200) {
          this.submitting = false;
          this.getData();
        }
      } catch (error) {
        console.log(error);
        console.log ("There was an error3")
      } finally {
        this.submitting = false;
      }
    }
    }
    ,
    async getRacerData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/racers");
        // JSON responses are automatically parsed.
        console.log(response);
        this.racers = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/times/");
        // JSON responses are automatically parsed.

        this.results = response.data;
        this.reqStatus = response;
      } catch (error) {
        console.log(error.toJSON());
        this.errorMsg = error.message;
        this.errorPopup = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getData();
    this.getRacerData();
  },
};
</script>

<style></style>
